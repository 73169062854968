<template>
    <div class="chapter" :class="cover" :style="{ backgroundImage: `url('${backgroundimg}')`}">
        <div class="container chapter__content">
            <div class="row chapter__row">
                <div v-show="member" class="chapter__col-lg">
                    <div class="chapter__frame">
                        <img class="chapter__img" :src="urlmember" alt="">
                    </div>
                </div>
                <div class="col-6">
                    <span v-show="status" class="chapter__label">{{position}}</span>
                    <h1 class="chapter__title">{{title}}</h1>
                    <p class="chapter__lead">{{lead}}</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: [
            'title',
            'lead',
            'member',
            'status',
            'position',
            'urlmember',
            'backgroundimg',
            'cover'
        ]
    }
</script>

<style scoped lang="scss">
    .chapter {
        position: relative;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        background-size: cover;
        background-position: 0 0;
        background-repeat: no-repeat;
        max-width: 1920px;
        margin: auto;
        height: 490px;
        @media (max-width: 575px) {
            height: 110px;
        }
        &_cover {
            &::after {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-color: rgba(4, 67, 136, .5);
            }
        }

        &__content {
            z-index: 11;
            @media (max-width: 575px) {
                padding-left: 40px;
            }
        }

        &__row {
            align-items: center;
        }

        &__col-lg {
           width: 210px;
           height: 210px;
           position: relative;
           min-height: 1px;
           padding-left: 0.9375rem;
           padding-right: 0.9375rem;
           @media (max-width: 575px) {
               width: 75px;
               height: 75px;
               padding-left: 0;
               padding-right: 0;
           }
        }

        &__frame {
            border-radius: 5px;
            border: 5px solid #fff;
            @media (max-width: 575px) {
                border: 3px solid #fff;
            }
        }

        &__img {
            width: 100%;
            height: auto;
        }

        &__label {
            font-family: "Roboto-Bold", Arial, Helvetica, sans-serif;
            font-size: 1.5rem;
            line-height: 1.75rem;
            color: #fff;
            text-transform: uppercase;
            @media (max-width: 575px) {
                font-size: 12px;
            }
        }

        &__title {
            font-family: "Montserrat-Bold", Arial, Helvetica, sans-serif;
            font-weight: 900;
            font-size: 2.5rem;
            line-height: 1;
            color: #fff;
            @media (max-width: 575px) {
                font-size: 18px;
            }
        }

        &__lead {
            color: #fff;
            font-size: 1.125rem;
            line-height: 1.6875rem;
            @media (max-width: 575px) {
                display: none;
            }
        }
    }
</style>
