
/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap');
import lity from 'lity'

window.Vue = require('vue');


import ClickOutside from 'vue-click-outside'
import axios from 'axios';

/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */

// const files = require.context('./', true, /\.vue$/i);
// files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default));

//Vue.component('example-component', require('./components/ExampleComponent.vue').default);
Vue.component('langbar', require('./components/Langbar.vue').default);
Vue.component('chapter', require('./components/Chapter.vue').default);


/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

const app = new Vue({
    el: '#app',

    data() {
        return {
            hamburgerActive: false,
            search: false,
            buttonsNavbar: false,
            testResponce: '90809890',
        }
    },

    methods: {
        toggleHamburger() {
            this.hamburgerActive = !this.hamburgerActive;
            //(this.hamburgerActiv) ? document.body.style.overflow = 'visible' : document.body.style.overflow = 'hidden';
        },

        toggleSearch() {
            this.search = !this.search;
            console.log(15)
        },
        turnSearch() {
            this.search = false
        },

        togglebuttonsNavbar() {
            this.buttonsNavbar = !this.buttonsNavbar
        },
        hideButtonsNavbar() {
            this.buttonsNavbar = false
        },
    },

    directives: {
        ClickOutside
    }
});
