<template>
    <div class="langbar ">
        <p class="langbar__label">Dil seçimi</p>

        <div class="langbar__wrap">
            <div v-click-outside="hideLangbar" class="langbar__span" @click="toggleLangbar">{{currentlang}}</div>

            <div class="langbar__box" :class="{active: langbar}" v-for="language in languages">
                <a @click="changeLangValue" :href="`/setlocale/`+language.language" class="langbar__init">{{language.name}}</a>
            </div>
        </div>

    </div>
</template>

<script>
    import ClickOutside from 'vue-click-outside';
    export default {
        props:['languages','currentlang'],
        mounted() {
            console.log('langbar Component mounted.')
        },

        data() {
            return {
               isVisible: true,
               langbar: false,
            }
        },

        methods: {
            toggleLangbar() {
                this.langbar = !this.langbar;
            },
            hideLangbar() {
                this.langbar = false;
            },

            changeLangValue(e) {
                this.langValue = e.target.textContent;
            },
        },

        directives: {
            ClickOutside
        }
    }
</script>

<style lang="scss" scoped>
    .langbar {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        padding-right: 18px;


        &__wrap {
            position: relative;
        }

        &__box {
            position: absolute;
            z-index: 200;
            top: 110%;
            left: 50%;
            display: flex;
            flex-direction: column;
            background-color: #fff;
            box-shadow: 0px 4px 70px rgba(1, 66, 105, 0.15);
            border-radius: 5px;
            padding: 5px;
            font-size: 0.75rem;
            opacity: 0;
            visibility: hidden;
            transform-origin: top;
            animation-fill-mode: forwards;
            transform: scale(0.9, 0.7) translate(-50%, -20px);
            transition: all 80ms ease;
            &.active {
                transform: scale(1, 1) translate(-50%, 0);
                opacity: 1;
                visibility: visible;
            }
        }

        &__span {
            cursor: pointer;
            text-transform: uppercase;
            font-family: "Roboto-Bold", Arial, Helvetica, sans-serif;
            color: #fff;
            font-size: 0.875rem;
        }

        &__init {
            cursor: pointer;
            text-transform: uppercase;
            font-family: "Roboto-Bold", Arial, Helvetica, sans-serif;
            color: #3b3b3b;
            padding: 5px 15px;
            &:not(:last-child) {
                margin-bottom: 5px;
            }
            &:hover {
                color: #0E54A1;
                text-decoration: underline;
            }
        }

        &__label {
            position: relative;
            margin-bottom: 0;
            padding-right: 0.625rem;
            margin-right: 0.625rem;
            font-size: 1rem;
            &::after {
                content: "";
                position: absolute;
                right: 0;
                top: 50%;
                transform: translateY(-50%);
                height: 1rem;
                border-right: 1px solid #fff;
            }
        }
    }
</style>
